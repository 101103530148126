<template>
  <div>
    <ManageQualificationExams />
  </div>
</template>
<script>
import ManageQualificationExams from "@/components/ManageQualificationExams/ManageQualificationExams";
export default {
  components: {
    ManageQualificationExams,
  },
  created() {},
};
</script>
